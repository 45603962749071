<template>
  <div>
    <div
      v-if="isShow"
      class="application-wrap"
      style="min-height: 500px; position: fixed;"
    >
      <button
        class="btn-close"
        @click.stop="close(false)"
      >
        닫기
      </button>
      <h1>이벤트 참여를 위한 사전 동의 안내</h1>
      <p class="s-title mgt20">
        이벤트 참여를 원하신다면 아래 내용 확인 후 동의 여부를 결정하여 주시기 바랍니다.
      </p>

      <div class="agree-box collecting-type">
        <dl class="mgt0">
          <dt>[필수] 개인정보 활용 사전 안내</dt>
          <dd>
            <div>
              <ol>
                <li><span>1.</span>개인정보 활용 항목 : 암호화 처리된 의사면허번호</li>
                <li><span>2.</span>개인정보 활용 목적 : 닥터인포 비회원의 개인 식별 및 참여 이력 관리</li>
                <li><span>3.</span>개인정보 활용 동의에 거부할 수 있으나, 거부할 경우 이벤트 참여가 불가합니다.</li>
                <li><span>4.</span>이벤트 참여 시, 이벤트에 참여한 내역을 닥터인포 [이벤트] 페이지에서 확인할 수 있습니다.</li>
              </ol>
            </div>
          </dd>
        </dl>
      </div>

      <div class="checkbox-wrap mgt20">
        <input
          id="che2"
          v-model="isPrivacyConfirm"
          type="checkbox"
          class="application-type"
          name="agreeType"
        >
        <label
          for="che2"
          class="f16"
        >개인정보 수집 및 이용에 동의합니다.</label>
      </div>

      <div class="btn-wrap">
        <button
          class="btn-application"
          @click.stop="submit"
        >
          동의하기
        </button>
      </div>
    </div>

    <alert-dialog :options="alertProps" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getUserLoginInfo } from '@/common/userInfo/service'

export default {
  props: {
    eventId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isPrivacyConfirm: false,
      isShow: false,
    }
  },

  computed: {
    ...mapGetters({
      join: 'util/getJoin', // 회원여부
      userName: 'account/getName',
      phoneNum: 'account/getPhoneNum',
      ysrUserName: 'infoData/getUserName',
      medicalDept: 'infoData/getMedicalDept',
      shaEnLicenseNum: 'infoData/getShaEnLicenseNum',
    }),
  },

  watch: {
    shaEnLicenseNum() {
      if (this.join === null) getUserLoginInfo()
    },
  },

  created() {
    // 회원여부 조회
    if (this.join === null) getUserLoginInfo()
  },

  methods: {
    async call() {
      this.isShow = true
    },

    submit() {
      if (!this.isPrivacyConfirm) {
        this.showAlertDialog('개인정보 활용에 동의하셔야 이벤트에 참여하실 수 있습니다.')
        return
      }

      this.close(true)
    },

    close(isConfirm) {
      this.isShow = false

      this.$emit('close', isConfirm)
    }
  }
}
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
}

img {
  display: block;
  margin: 0 auto;
}

area:hover {
  cursor: pointer;
}

.event-board {
  width: 1000px;
  margin: 0 auto;
  position: relative;
}

</style>

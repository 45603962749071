var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isShow
        ? _c(
            "div",
            {
              staticClass: "application-wrap",
              staticStyle: { "min-height": "500px", position: "fixed" },
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn-close",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.close(false)
                    },
                  },
                },
                [_vm._v(" 닫기 ")]
              ),
              _c("h1", [_vm._v("이벤트 참여를 위한 사전 동의 안내")]),
              _c("p", { staticClass: "s-title mgt20" }, [
                _vm._v(
                  " 이벤트 참여를 원하신다면 아래 내용 확인 후 동의 여부를 결정하여 주시기 바랍니다. "
                ),
              ]),
              _vm._m(0),
              _c("div", { staticClass: "checkbox-wrap mgt20" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.isPrivacyConfirm,
                      expression: "isPrivacyConfirm",
                    },
                  ],
                  staticClass: "application-type",
                  attrs: { id: "che2", type: "checkbox", name: "agreeType" },
                  domProps: {
                    checked: Array.isArray(_vm.isPrivacyConfirm)
                      ? _vm._i(_vm.isPrivacyConfirm, null) > -1
                      : _vm.isPrivacyConfirm,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.isPrivacyConfirm,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.isPrivacyConfirm = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.isPrivacyConfirm = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.isPrivacyConfirm = $$c
                      }
                    },
                  },
                }),
                _c("label", { staticClass: "f16", attrs: { for: "che2" } }, [
                  _vm._v("개인정보 수집 및 이용에 동의합니다."),
                ]),
              ]),
              _c("div", { staticClass: "btn-wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn-application",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.submit($event)
                      },
                    },
                  },
                  [_vm._v(" 동의하기 ")]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "agree-box collecting-type" }, [
      _c("dl", { staticClass: "mgt0" }, [
        _c("dt", [_vm._v("[필수] 개인정보 활용 사전 안내")]),
        _c("dd", [
          _c("div", [
            _c("ol", [
              _c("li", [
                _c("span", [_vm._v("1.")]),
                _vm._v("개인정보 활용 항목 : 암호화 처리된 의사면허번호"),
              ]),
              _c("li", [
                _c("span", [_vm._v("2.")]),
                _vm._v(
                  "개인정보 활용 목적 : 닥터인포 비회원의 개인 식별 및 참여 이력 관리"
                ),
              ]),
              _c("li", [
                _c("span", [_vm._v("3.")]),
                _vm._v(
                  "개인정보 활용 동의에 거부할 수 있으나, 거부할 경우 이벤트 참여가 불가합니다."
                ),
              ]),
              _c("li", [
                _c("span", [_vm._v("4.")]),
                _vm._v(
                  "이벤트 참여 시, 이벤트에 참여한 내역을 닥터인포 [이벤트] 페이지에서 확인할 수 있습니다."
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }